import axios, { type AxiosResponse } from "axios"

import type {
	TypeTrain,
	TypeResponsePagination,
	TypeGetRequestParamsPagination,
	TypeHashData,
	TypeTrainOrdering,
	TypeOccupation,
	TypeMaintenancePlan,
	TypeShift,
	TypeMaintenancePlanEdited,
	TypeSubTrackMatrix,
	TypeInspection,
	TypeUser,
	TypeArchive,
	TypeMaintenanceFact,
	TypeMaintenanceFactEdited,
	TypeStaffField,
	TypeStaffEdited,
	TypeWorkshop,
	TypeOccupationEdited,
	TypeCode,
	TypeOccupationOrdering,
	TypeTrainFilters,
	TypeOccupationFilters,
	TypeOccupationStatisticsResponse,
	TypeOccupationStatisticsPeriod,
	TypeDepot,
	TrackOccupationsForDepot,
	TypeTrainEdited,
	TypeTrack,
	TypeTrainCard,
	TypeTrainCardEdited,
	TypeTrainCardColumn,
	TypeTrainCardColumnEdited,
	TypeStopReason,
	TypeTrainCardCommentEdited,
	TypePlannerLocation,
	TypeTrainCardHistory,
	TypePlannerCategory,
	TypeTrainCardComment,
	TypeTrainCardTask,
	TypeTrainCardTaskEdited,
	TypePlannerCategoryEdited,
	TypePlannerLocationEdited
} from "@/types"

import ApiDoc from "@/api/api-doc"
import ApiSecure from "@/api/api-secure"

import { response2element } from "@/utils/helpers"

export function getUserInfo(quiet: boolean = true): Promise<TypeUser> {
	return ApiSecure.get("profile/self/", { NO_REDIRECT: true, QUIET: quiet } as never)
}

export function getGetTokensByHash(hash: string): Promise<AxiosResponse<TypeHashData>> {
	return axios.get(`http://217.148.217.231:11010/token/${hash}/`)
}

// 1. TASK
// 2. SUB TASK
// 3. TASK GROUP
// 4. MAINTENANCE PLAN

export function getMaintenancePlan(params: {
	shift_id: TypeShift["id"]
}): Promise<TypeResponsePagination<TypeMaintenancePlan>> {
	return ApiSecure.get(`maintenance_plan/`, { params })
}

export function postMaintenancePlan(data: TypeMaintenancePlanEdited): Promise<TypeMaintenancePlan> {
	return ApiSecure.post("maintenance_plan/", data)
}

export function putMaintenancePlan(
	id: TypeMaintenancePlan["id"],
	data: TypeMaintenancePlanEdited
): Promise<TypeMaintenancePlan> {
	return ApiSecure.put(`maintenance_plan/${id}/`, data)
}

export function deleteMaintenancePlan(id: TypeMaintenancePlan["id"]) {
	return ApiSecure.delete(`maintenance_plan/${id}/`)
}

export async function getMaintenancePlanPdfFile(data: {
	shift_id: TypeShift["id"]
	note?: string
}): Promise<null | HTMLAnchorElement> {
	const response = await ApiDoc.post(`maintenance_plan/download_pdf_file/`, data, {
		responseType: "blob"
	})
	if (response.shouldDownload) {
		return response2element(response)
	}
	return null
}

// 5. SUB TRACK

export function getSubTracksMatrix(): Promise<TypeSubTrackMatrix> {
	return ApiSecure.get("sub_tracks/")
}

export function getTracks(params: { depot_id?: TypeDepot["id"] }): Promise<TypeResponsePagination<TypeTrack>> {
	return ApiSecure.get("tracks/", { params })
}

// 6. TRAIN

export function getTrains(
	params?: TypeGetRequestParamsPagination<TypeTrainOrdering, TypeTrainFilters>
): Promise<TypeResponsePagination<TypeTrain>> {
	return ApiSecure.get("trains/", { params })
}

export function getTrainById(id: TypeTrain["id"]): Promise<TypeTrain> {
	return ApiSecure.get(`trains/${id}/`)
}

export function putTrain(id: TypeTrain["id"], data: TypeTrainEdited): Promise<TypeTrain> {
	return ApiSecure.put(`trains/${id}/`, data)
}

// 7. SHIFT

export function getCurrentShift(): Promise<Array<TypeShift>> {
	return ApiSecure.get("shifts/get_by_date/")
}

export function getShifts(): Promise<TypeResponsePagination<TypeShift>> {
	return ApiSecure.get("shifts/")
}

// 8. INSPECTION

export function getInspections(): Promise<TypeResponsePagination<TypeInspection>> {
	return ApiSecure.get("inspections/")
}

// 9. ARCHIVE

export function getArchive(params: { date: string }): Promise<TypeArchive> {
	return ApiSecure.get("archive/get_by_date/", { params })
}

export async function getMaintenancePlanArchive(
	params: { document_id: string },
	fileName: string
): Promise<null | HTMLAnchorElement> {
	const response = await ApiDoc.get("maintenance_plan/download/", {
		params,
		responseType: "blob"
	})
	if (response.shouldDownload) {
		return response2element(response, fileName)
	}
	return null
}
// 10. MAINTENANCE FACT
export function putMaintenanceFact(
	id: TypeMaintenanceFact["id"],
	data: TypeMaintenanceFactEdited
): Promise<TypeMaintenanceFact> {
	return ApiSecure.put(`maintenance_fact/${id}/`, data)
}

export function getMaintenanceFact(params: {
	shift_id: TypeShift["id"]
}): Promise<TypeResponsePagination<TypeMaintenanceFact>> {
	return ApiSecure.get(`maintenance_fact/`, { params })
}

export async function getMaintenanceFactPdfFile(data: {
	shift_id: TypeShift["id"]
	note?: string
}): Promise<null | HTMLAnchorElement> {
	const response = await ApiDoc.post(`maintenance_fact/download_pdf_file/`, data, {
		responseType: "blob"
	})
	if (response.shouldDownload) {
		return response2element(response)
	}
	return null
}

export function getMaintenanceFactStaff(id: TypeShift["id"]): Promise<TypeStaffField[]> {
	return ApiSecure.get(`staff_types/for_shift/?shift_id=${id}`)
}
export function putMaintenanceFactStaff(data: {
	shift_id: TypeShift["id"]
	flattened: TypeStaffEdited
}): Promise<TypeStaffField[]> {
	return ApiSecure.post("staff_types/put_for_shift/", data)
}

// 11. TRACK OCCUPATION

export function getTrackOccupationsForDepot(): Promise<TrackOccupationsForDepot> {
	return ApiSecure.get("track_occupations/depot/")
}

export function getTrackOccupations(
	params?: TypeGetRequestParamsPagination<TypeOccupationOrdering, TypeOccupationFilters>
): Promise<TypeResponsePagination<TypeOccupation>> {
	return ApiSecure.get("track_occupations/", { params })
}

export function postTrackOccupation(data: TypeOccupationEdited): Promise<TypeOccupation> {
	return ApiSecure.post("track_occupations/", data)
}

export function putTrackOccupation(id: TypeOccupation["id"], data: TypeOccupationEdited): Promise<TypeOccupation> {
	return ApiSecure.put(`track_occupations/${id}/`, data)
}

export function deleteTrackOccupation(id: TypeOccupation["id"]) {
	return ApiSecure.delete(`track_occupations/${id}/`)
}

export async function getTrackOccupationsReport(
	params?: TypeGetRequestParamsPagination<TypeOccupationOrdering, TypeOccupationFilters>
): Promise<null | HTMLAnchorElement> {
	const response = await ApiDoc.get("track_occupations/latest_data_file/", {
		responseType: "blob",
		params
	})
	if (response.shouldDownload) {
		return response2element(response)
	}
	return null
}

// 12. TRACK OCCUPATION: CODES

export function getTrackOccupationCodes(): Promise<TypeResponsePagination<TypeCode>> {
	return ApiSecure.get("toc_codes/")
}

export function getTrackOccupationCodesById(id: TypeCode["id"]): Promise<TypeCode> {
	return ApiSecure.get(`toc_codes/${id}/`)
}

// 13. TRACK OCCUPATION: STATISTICS

export async function getTrackOccupationStatistics(
	period: TypeOccupationStatisticsPeriod,
	workshopId: TypeWorkshop["id"],
	abortController?: AbortController
): Promise<TypeOccupationStatisticsResponse> {
	return await ApiSecure.get("track_occupations/statistics/", {
		params: {
			value: period.value,
			type: period.state,
			workshop_id: workshopId
		},
		signal: abortController?.signal
	})
}

// 14. Workshops

export function getWorkshops(params: {
	toc: boolean
	depot_id?: TypeDepot["id"]
}): Promise<TypeResponsePagination<TypeWorkshop>> {
	return ApiSecure.get("workshops/", { params })
}

// 15. DEPOT

export function getDepots(): Promise<TypeResponsePagination<TypeDepot>> {
	return ApiSecure.get("depots/")
}

// 16. TRAIN CARD

export function getTrainCards(
	params?: TypeGetRequestParamsPagination<TypeTrainOrdering, TypeTrainFilters>
): Promise<TypeResponsePagination<TypeTrainCard>> {
	return ApiSecure.get("planner/train_cards/", { params })
}

export function getTrainCard(id: TypeTrainCard["id"]): Promise<TypeTrainCard> {
	return ApiSecure.get(`planner/train_cards/${id}/`)
}

export function postTrainCard(data: TypeTrainCardEdited): Promise<TypeTrainCard> {
	return ApiSecure.post("planner/train_cards/", data)
}

export function putTrainCard(id: TypeTrainCard["id"], data: TypeTrainCardEdited): Promise<TypeTrainCard> {
	return ApiSecure.put(`planner/train_cards/${id}/`, data)
}

export function deleteTrainCard(id: TypeTrainCard["id"]): Promise<null | undefined> {
	return ApiSecure.delete(`planner/train_cards/${id}/`)
}

export function getTrainCardTasks(
	trainCardId: TypeTrainCard["id"]
): Promise<TypeResponsePagination<TypeTrainCardTask>> {
	return ApiSecure.get(`planner/train_tasks/`, { params: { train_card_id: trainCardId } })
}

export function postTrainCardTask(data: TypeTrainCardTaskEdited): Promise<TypeTrainCardTask> {
	return ApiSecure.post(`planner/train_tasks/`, data)
}

export function putTrainCardTask(
	taskId: TypeTrainCardTask["id"],
	data: TypeTrainCardTaskEdited
): Promise<TypeTrainCardTask> {
	return ApiSecure.put(`planner/train_tasks/${taskId}/`, data)
}

export function deleteTrainCardTask(taskId: TypeTrainCardTask["id"]): Promise<null | undefined> {
	return ApiSecure.delete(`planner/train_tasks/${taskId}/`)
}

export function postTrainCardComment(data: TypeTrainCardCommentEdited): Promise<TypeTrainCardComment> {
	return ApiSecure.post(`planner/train_cards/comment/`, data)
}

export function putTrainCardComment(
	id: TypeTrainCardComment["id"],
	data: TypeTrainCardCommentEdited
): Promise<TypeTrainCardComment> {
	return ApiSecure.put(`planner/train_cards/comment/${id}/`, data)
}

export function deleteTrainCardComment(id: TypeTrainCardComment["id"]): Promise<null | undefined> {
	return ApiSecure.delete(`planner/train_cards/comment/${id}/`)
}

export function getTrainCardHistory(id: TypeTrainCard["id"]): Promise<TypeResponsePagination<TypeTrainCardHistory>> {
	return ApiSecure.get(`planner/train_cards/${id}/history/`)
}

// 17. TRAIN CARD COLUMN

export function getTrainCardColumns(): Promise<TypeResponsePagination<TypeTrainCardColumn>> {
	return ApiSecure.get("planner/train_card_columns/")
}

export function postTrainCardColumn(data: TypeTrainCardColumnEdited): Promise<TypeTrainCardColumn> {
	return ApiSecure.post("planner/train_card_columns/", data)
}

export function putTrainCardColumn(
	id: TypeTrainCardColumn["id"],
	data: TypeTrainCardColumnEdited
): Promise<TypeTrainCardColumn> {
	return ApiSecure.put(`planner/train_card_columns/${id}/`, data)
}

export function deleteTrainCardColumn(id: TypeTrainCardColumn["id"]): Promise<null | undefined> {
	return ApiSecure.delete(`planner/train_card_columns/${id}/`)
}

// 18. STOP REASON

export function getStopReasons(): Promise<TypeResponsePagination<TypeStopReason>> {
	return ApiSecure.get("planner/stop_reasons/")
}

export function getStopReason(id: TypeStopReason["id"]): Promise<TypeStopReason> {
	return ApiSecure.get(`planner/stop_reasons/${id}/`)
}

export function postStopReason(data: TypeStopReason): Promise<TypeStopReason> {
	return ApiSecure.post("planner/stop_reasons/", data)
}

export function putStopReason(id: TypeStopReason["id"], data: TypeStopReason): Promise<TypeStopReason> {
	return ApiSecure.put(`planner/stop_reasons/${id}/`, data)
}

export function deleteStopReason(id: TypeStopReason["id"]) {
	return ApiSecure.delete(`planner/stop_reasons/${id}/`)
}

// 19. PLANNER

export function getPlanner(): Promise<TypeResponsePagination<TypePlannerLocation>> {
	return ApiSecure.get("planner/locations/")
}

// 20. USER

export function getUsers(): Promise<TypeResponsePagination<TypeUser>> {
	return ApiSecure.get("users/")
}

// 21. FILE
export async function getFile(id: string, fullFileName: string): Promise<null | HTMLAnchorElement> {
	const response = await ApiDoc.get(`files/${id}/`, {
		responseType: "blob"
	})
	if (response.shouldDownload) {
		return response2element(response, undefined, fullFileName)
	}
	return null
}

export function deleteFile(id: string): Promise<any> {
	return ApiSecure.delete(`files/${id}/`)
}

// 22. CATEGORY

export function postCategory(category: TypePlannerCategoryEdited): Promise<TypePlannerCategory> {
	return ApiSecure.post("planner/categories/", category)
}

export function putCategory(
	id: TypePlannerCategory["id"],
	data: TypePlannerCategoryEdited
): Promise<TypePlannerCategory> {
	return ApiSecure.put(`planner/categories/${id}/`, data)
}

export function deleteCategory(id: TypePlannerCategory["id"]) {
	return ApiSecure.delete(`planner/categories/${id}/`)
}

// 23. LOCATION

export function postLocation(location: TypePlannerLocationEdited): Promise<TypePlannerLocation> {
	return ApiSecure.post("planner/locations/", location)
}

export function putLocation(
	id: TypePlannerLocation["id"],
	data: TypePlannerLocationEdited
): Promise<TypePlannerLocation> {
	return ApiSecure.put(`planner/locations/${id}/`, data)
}

export function deleteLocation(id: TypePlannerLocation["id"]) {
	return ApiSecure.delete(`planner/locations/${id}/`)
}
