/**
 * plugins/index.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

import { createVuetify, type ThemeDefinition } from "vuetify"
import { aliases, mdi } from "vuetify/iconsets/mdi-svg"
import { ru } from "vuetify/locale"
import "vuetify/styles"

const light: ThemeDefinition = {
	dark: false,
	colors: {
		background: "#f6f6f6",
		surface: "#f6f6f6",
		primary: "#b91716",
		secondary: "#333333",
		accent: "#b91716",
		error: "#E28413",
		info: "#00A5CF",
		success: "#226F54",
		warning: "#E28413",
		faded: "#717171"
	}
}
const dark: ThemeDefinition = {
	dark: true, // Enable dark mode
	colors: {
		background: "#212529", // Your chosen dark, warm background
		surface: "#282C30", // A slightly lighter but still dark shade for surfaces
		"surface-bright": "#282C30",
		"surface-light": "#303438",
		primary: "#b91716", // Keeping the company's main color
		secondary: "#e0a899", // A lighter, complementary shade for secondary elements
		accent: "#FF5252", // A bright accent, adjust if needed
		error: "#E28413", // Bright yellow for errors, distinct from the primary red
		info: "#00A5CF", // A lighter, warm color for info that complements the background
		success: "#4CAF50", // A vivid green for success states, consider adjusting for harmony
		warning: "#FFC107", // A bright amber for warnings
		faded: "#b0b2b3" // A muted color for less emphasized elements, to match the warm tones
	}
}

export default createVuetify({
	locale: {
		locale: import.meta.env.VITE_I18N_LOCALE || "ru",
		fallback: import.meta.env.VITE_I18N_FALLBACK_LOCALE || "ru",
		messages: { ru }
	},
	theme: {
		defaultTheme: "light",
		themes: {
			light,
			dark
		}
	},
	icons: {
		defaultSet: "mdi",
		aliases: {
			...aliases
		},
		sets: {
			mdi
		}
	},
	defaults: {
		VTextField: {
			variant: "solo-filled",
			flat: true,
			rounded: "lg"
		},
		VSelect: {
			rounded: "lg",
			menuProps: {
				scrollStrategy: "close",
				maxWidth: 0
			}
		},
		VAutocomplete: {
			rounded: "lg",
			menuProps: {
				scrollStrategy: "close",
				maxWidth: 0
			}
		},
		VCombobox: {
			rounded: "lg",
			menuProps: {
				scrollStrategy: "close",
				maxWidth: 0
			}
		},
		VDatePicker: {
			rounded: "lg",
			menuProps: {
				scrollStrategy: "close",
				maxWidth: 0
			}
		},
		VTextarea: {
			rounded: "lg"
		},
		VFileInput: {
			rounded: "lg"
		}
	}
})
