import type { TypeOccupationStatistics, TypeOccupationStatisticsData, TypePermission } from "@/types"
import type { AxiosResponse } from "axios"

import { useUserStore } from "@/store/user"

import { PERMISSIONS_ALL } from "@/utils/mappers"

export const getTimezoneOffset = (timezone: string): string => {
	return (
		new Date()
			.toLocaleTimeString("en-US", { timeZone: timezone, timeZoneName: "longOffset" })
			.match(/([+-]\d{2}):?(\d{2})?/)?.[0] ?? "+03:00"
	)
}
export const backend2string = (datetime?: string): string => {
	if (!datetime) return ""
	const userTimezone = useUserStore().getUserTimezone
	const date = new Date(datetime.replace(" ", "T"))

	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: false,
		timeZone: userTimezone
	}

	const formatter = new Intl.DateTimeFormat("en-US", options)
	const parts = formatter.formatToParts(date)

	const year = parts.find((p) => p.type === "year")?.value
	const month = parts.find((p) => p.type === "month")?.value
	const day = parts.find((p) => p.type === "day")?.value
	const hour = parts.find((p) => p.type === "hour")?.value.padStart(2, "0")
	const minute = parts.find((p) => p.type === "minute")?.value.padStart(2, "0")
	const seconds = parts.find((p) => p.type === "second")?.value.padStart(2, "0")

	if (seconds === "59") {
		return `${year}-${month}-${day}` // YYYY-MM-DD
	}
	return `${year}-${month}-${day}T${hour}:${minute}` // YYYY-MM-DDTHH:MM
}

export const string2backend = (datetime: string): string => {
	// backend expects the time in the format "YYYY-MM-DDTHH:MM:SS+HH:MM"
	// If the time is not provided, it defaults to 23:59:59
	const userTimeZone = useUserStore().getUserTimezone
	const timezoneOffset = getTimezoneOffset(userTimeZone)
	if (datetime.includes("T")) return `${datetime}:00${timezoneOffset}`
	return `${datetime}T23:59:59${timezoneOffset}`
}

export const backend2display = (datetime?: string, timeOnly = false, dateOnlyParsing = true): string => {
	// convert YYYY-MM-DD HH:MM:SS+HH:MM to ru-RU local time string in the user's timezone
	// timeOnly: return only the time and ignore the date
	// dateOnlyParsing: if the time is 23:59:59, return only the date
	if (!datetime) return ""
	const userTimezone = useUserStore().getUserTimezone
	const date = new Date(datetime.replace(" ", "T"))

	if (timeOnly) {
		return date.toLocaleString("ru-RU", {
			hour: "numeric",
			minute: "numeric",
			timeZone: userTimezone
		})
	} else {
		const localeString = date.toLocaleString("ru-RU", {
			hour: "numeric",
			minute: "numeric",
			second: "2-digit",
			day: "numeric",
			month: "numeric",
			year: "2-digit",
			timeZone: userTimezone
		})
		if (dateOnlyParsing) {
			if (localeString.endsWith("23:59:59")) {
				return localeString.replace(", 23:59:59", "") //Remove the time (, 23:59:59)
			}
			return localeString.slice(0, -3) // Remove the seconds (:SS)
		}
		return localeString
	}
}

export const seconds2string = (seconds: number, short = false, timezone?: string): string => {
	if (!seconds) return "-"
	const date = new Date(seconds * 1000)
	const userStore = useUserStore()
	const timeZone = timezone || userStore.getUserTimezone
	if (short) {
		// Return only the time for the short format
		return date.toLocaleString("ru-RU", {
			hour: "numeric",
			minute: "numeric",
			timeZone
		})
	} else {
		// Return the full date and time
		const localeString = date.toLocaleString("ru-RU", {
			hour: "numeric",
			minute: "numeric",
			second: "2-digit",
			day: "numeric",
			month: "numeric",
			year: "2-digit",
			timeZone
		})
		if (localeString.endsWith("23:59:59")) {
			return localeString.replace(", 23:59:59", "") //Remove the time (, 23:59:59)
		}
		return localeString.slice(0, -3) // Remove the seconds (:SS)
	}
}

export const seconds2ISO = (seconds?: number, timezone?: string): string => {
	if (!seconds) return ""
	const ms = seconds * 1000
	const dateUTC = new Date(ms)
	const userStore = useUserStore()
	const timeZone = timezone || userStore.getUserTimezone
	let date = dateUTC.toLocaleString("sv-SE", {
		timeZone
	})
	date = date.replace(" ", "T").replace(",", "")
	if (date.endsWith("T23:59:59")) {
		date = date.slice(0, -9) // Remove T23:59:59
	} else {
		date = date.slice(0, -3) // Remove the seconds (:SS)
	}
	return date
}

export const ISO2backend = (ISO: string): string => {
	// backend expects the time in the format "YYYY-MM-DDTHH:MM:SS"
	// If the time is not provided, it defaults to 23:59:59
	const userStore = useUserStore()
	const timeZone = userStore.getUserTimezone
	const timezoneOffset = getTimezoneOffset(timeZone)
	if (ISO.includes("T")) return ISO + ":00" + timezoneOffset
	return ISO + "T23:59:59" + timezoneOffset
}

const isSameDay = (secondsStart: number, secondsEnd: number): boolean => {
	const startDate = new Date(secondsStart * 1000)
	const endDate = new Date(secondsEnd * 1000)
	return startDate.toDateString() === endDate.toDateString()
}

export const formatShiftTime = (startSeconds: number, endSeconds: number) => {
	if (isSameDay(startSeconds, endSeconds)) {
		// If the start and end are on the same day, show the date once followed by the start and end times
		return `${seconds2string(startSeconds, false, "UTC")} — ${seconds2string(endSeconds, true, "UTC")}`
	} else {
		// If they span different days, show full details for both
		return `${seconds2string(startSeconds, false, "UTC")} — ${seconds2string(endSeconds, false, "UTC")}`
	}
}

const mimeToExtension: Record<string, string> = {
	"application/pdf": ".pdf",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
	"application/vnd.ms-excel": ".xls",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx",
	"application/msword": ".doc",
	"application/zip": ".zip",
	"application/x-rar-compressed": ".rar",
	"application/x-7z-compressed": ".7z",
	"image/jpeg": ".jpg",
	"image/png": ".png",
	"image/gif": ".gif",
	"image/bmp": ".bmp",
	"image/webp": ".webp",
	"image/svg+xml": ".svg",
	"video/mp4": ".mp4",
	"video/mpeg": ".mpeg"
}

export const response2element = (
	response: AxiosResponse,
	fileName?: string,
	fileNameWithExtension?: string
): HTMLAnchorElement => {
	const bp = response.data
	const blob = new Blob([bp], {
		type: response.headers["content-type"]
	})
	const elem = document.createElement("a")
	const URL = window.URL || window.webkitURL
	const downloadUrl = URL.createObjectURL(blob)
	elem.href = downloadUrl
	elem.style.display = "none"
	elem.download = fileNameWithExtension || getFileName(response, fileName)
	document.body.appendChild(elem)
	setTimeout(function () {
		document.body.removeChild(elem)
		URL.revokeObjectURL(downloadUrl)
	}, 100)
	return elem
}

export const getFileName = (response: AxiosResponse, fileName?: string): string => {
	let nameFromHeader = response.headers["content-disposition"]?.split("filename*=")[1]
	nameFromHeader = nameFromHeader?.split("''")[1]
	nameFromHeader = nameFromHeader ? decodeURIComponent(nameFromHeader) : null

	const contentType = response.headers["content-type"]
	const extension = mimeToExtension[contentType] || ".txt"

	if (fileName) {
		return fileName + extension
	} else if (nameFromHeader) {
		return nameFromHeader
	} else {
		return `file${extension}`
	}
}

export const getBackendDate = (date: string): string => {
	const [year, month, day] = date.split("-")
	return `${day}-${month}-${year}`
}

export const can = (permission?: TypePermission): boolean => {
	if (!permission) return true
	const permissions = useUserStore().getPermissions
	if (!permissions) return false
	if (permissions.includes(PERMISSIONS_ALL)) return true
	return permissions.includes(permission)
}

export const datetimeNow = (addMinutes: number = 0) => {
	const userStore = useUserStore()
	const timezone = userStore.getUserTimezone
	const offset = addMinutes * 60 * 1000
	const nowWithOffset = new Date(new Date().getTime() + offset)
	const localeString = nowWithOffset.toLocaleString("sv-SE", {
		timeZone: timezone
	})
	return localeString.replace(" ", "T").replace(",", "").substring(0, 16)
}

export const offsetDatetime = (iso: string, addMinutes: number = 0) => {
	const dateISO = new Date(`${iso}:00Z`)
	const offset = addMinutes * 60 * 1000
	const newDate = new Date(dateISO.getTime() + offset)
	return newDate.toISOString().substring(0, 16)
}

/**
 * Calculate the ranges for each stack in the occupation statistics
 * @param tracks - The occupation statistics for each track
 * @returns The occupation statistics with the ranges calculated
 */
export const calculateRanges = (tracks: TypeOccupationStatistics[]): TypeOccupationStatistics[] => {
	const calculateRange = (data: TypeOccupationStatisticsData): TypeOccupationStatisticsData => {
		const result: TypeOccupationStatisticsData = {}
		const cumulative: Record<string, number> = {}

		// Initialize cumulative values for all stacks
		stacks.forEach((stack) => (cumulative[stack.key] = 0))

		const getPreviousStackCummulative = (currentOrder: number) => {
			if (currentOrder > 0) {
				const prevStack = stacks.find((stack) => stack.order === currentOrder - 1)
				return prevStack ? cumulative[prevStack.key] : 0
			}
			return 0
		}

		// Process stacks by priority
		stacks
			.sort((a, b) => a.order - b.order)
			.forEach(({ order }) => {
				Object.keys(data)
					.filter((key) => getOrder(key) === order)
					.forEach((key) => {
						const stackKey = getStack(key)
						const min = cumulative[stackKey] || getPreviousStackCummulative(order)
						const max = min + data[key].hours

						// Update cumulative and assign the range
						cumulative[stackKey] = max
						result[key] = {
							...data[key],
							hoursRange: [min, max]
						}
					})
			})

		return result
	}

	// Clone and compute ranges for each track
	return tracks.map((track) => ({
		...track,
		general: calculateRange(track.general),
		detailed: calculateRange(track.detailed)
	}))
}

export type TypeStack = {
	key: string
	order: number
	pattern?: RegExp
}

export const stacks: TypeStack[] = [
	{ key: "other", order: 0 },
	{ key: "VSM", order: 1, pattern: /^VSM_/ },
	{ key: "RZD", order: 2, pattern: /^RZD_/ }
]

export const getStack = (key: string): string => {
	const stack = stacks.find((stack) => stack.pattern?.test(key))
	return stack ? stack.key : "other"
}

export const getOrder = (key: string): number => {
	const stack = stacks.find((stack) => stack.pattern?.test(key))
	return stack ? stack.order : 0
}
