import type { App } from "vue"
import type { Router } from "vue-router"

import * as Sentry from "@sentry/vue"
export default function initSentry(app: App, router: Router) {
	Sentry.init({
		environment: import.meta.env.MODE,
		app,
		dsn: import.meta.env.VITE_SENTRY_CONFIG_DSN,
		integrations: [
			Sentry.replayIntegration(),
			Sentry.browserTracingIntegration({ router }),
			Sentry.vueIntegration({
				app,
				attachProps: true,
				tracingOptions: {
					trackComponents: true
				}
			})
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			"localhost",
			/^https:\/\/planner\.vsmservice\.ru\//,
			/^http:\/\/217\.198\.83\.166:30141\//,
			/^http:\/\/217\.198\.83\.167:30141\//
			// /^http:\/\/217\.198\.83\.166:30180\/api\//,
			// /^http:\/\/217\.198\.83\.167:30180\/api\//,
			// /^https:\/\/backend-planner\.vsmservice\.ru\/api\//,
			// /^http:\/\/217\.148\.217\.231:11580\//,
			// /^http:\/\/217\.148\.217\.231:11080\//,
			// /^http:\/\/217\.148\.217\.231:11010\//
		],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		beforeSend: (event, hint) => {
			if (import.meta.env.MODE !== "production") {
				console.error(hint.originalException || hint.syntheticException)
				return null
			}
			return event
		}
	})
}
