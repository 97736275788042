<script setup lang="ts">
import type { PropType } from "vue"

import { mdiAccountCircleOutline, mdiEmailOutline } from "@mdi/js"
import { computed, ref } from "vue"
import { useDisplay } from "vuetify"

import type { TypeIcons, TypeUser, TypeVChipSize } from "@/types"

const display = useDisplay()

const prop = defineProps({
	user: {
		type: Object as PropType<TypeUser>,
		required: true
	},
	size: {
		type: String as PropType<TypeVChipSize>,
		default: "small"
	},
	label: {
		type: Boolean,
		default: true
	},
	disabled: {
		type: Boolean,
		default: false
	},
	fullName: {
		type: Boolean,
		default: false
	},
	shortName: {
		type: Boolean,
		default: false
	}
})
const icons: TypeIcons = {
	email: mdiEmailOutline,
	account: mdiAccountCircleOutline
}
const menu = ref(false)

const nameDisplayLong = computed(() => {
	return `${prop.user?.last_name} ${prop.user?.first_name} ${prop.user?.middle_name}`
})
const nameDisplayShort = computed(() => {
	return `${prop.user?.last_name} ${prop.user?.first_name?.charAt(0)}. ${prop.user?.middle_name?.charAt(0)}.`
})
const nameDisplay = computed(() => {
	if (prop.fullName && prop.shortName) throw new Error("fullName and shortName cannot be true at the same time")
	if (prop.fullName) return nameDisplayLong.value
	if (prop.shortName) return nameDisplayShort.value
	return display.smAndDown.value ? nameDisplayShort.value : nameDisplayLong.value
})
const roleDisplay = computed(() => {
	return prop.user?.role || "Сотрудник"
})
const idDisplay = computed(() => {
	return `#${prop.user?.id || "xxxxxxx"}`
})
</script>

<template>
	<v-menu v-model="menu" :close-on-content-click="false" :offset="10" transition="scale-transition" min-width="auto">
		<template #activator="{ props }">
			<v-chip :size="prop.size" :label="prop.label" v-bind="props" :disabled="disabled" @click.stop>
				<v-icon class="mr-2" size="small">
					{{ icons.account }}
				</v-icon>
				<span style="text-decoration: none">{{ nameDisplay }}</span>
			</v-chip>
		</template>
		<v-list density="compact" :nav="true" class="py-0">
			<v-list-item lines="three">
				<v-list-item-subtitle>
					<strong>Пользователь</strong>
					<v-chip size="x-small" :label="true" class="ml-2"> {{ idDisplay }} </v-chip>
					<v-chip size="x-small" class="ml-2">
						{{ roleDisplay }}
					</v-chip>
				</v-list-item-subtitle>
				<v-list-item-title>
					{{ nameDisplay }}
				</v-list-item-title>
				<v-list-item-subtitle>
					<template v-if="user.email && user.email !== 'nan'">
						{{ user.email }}
					</template>
					<span v-else class="font-italic"> Почта не указана </span>
				</v-list-item-subtitle>
			</v-list-item>
			<v-list-item>
				<v-list-item-subtitle>
					<strong>Должность и обособленное подразделение</strong>
				</v-list-item-subtitle>
				<v-list-item-title>
					<template v-if="user.position && user.position !== 'nan'">
						{{ user.position }}
					</template>
					<span v-else class="font-italic"> Позиция не указана </span>
				</v-list-item-title>
				<v-list-item-subtitle>
					<template v-if="user.department && user.department !== 'nan'">
						{{ user.department }}
					</template>
					<span v-else class="font-italic"> Подразделение не указано </span>
				</v-list-item-subtitle>
			</v-list-item>
			<slot />
		</v-list>
	</v-menu>
</template>
