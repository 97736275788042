import { captureException, captureMessage } from "@sentry/vue"
import { defineStore } from "pinia"

import type { TypeUserCredentials, TypeUser } from "@/types"

import { getUserInfo } from "@/api/requests"

interface State {
	credentials: TypeUserCredentials
	user: TypeUser
}

export const useUserStore = defineStore("userStore", {
	state: (): State => ({
		user: {
			id: 0,
			email: "",
			role: "",
			permissions: [],
			last_name: "",
			first_name: "",
			middle_name: "",
			position: "",
			department: "",
			depot: undefined,
			number: ""
		},
		credentials: {
			email: "",
			password: "",
			newPassword: "",
			confirmationPassword: "",
			captcha: "",
			code: ""
		}
	}),

	getters: {
		getCredentialsEmail: (state) => state.credentials.email,
		getCredentialsPassword: (state) => state.credentials.password,
		getCredentialsNewPassword: (state) => state.credentials.newPassword,
		getCredentialsConfirmationPassword: (state) => state.credentials.confirmationPassword,
		getCredentialsCode: (state) => state.credentials.code,
		isLoggedIn: (state) => !!state.user.id,
		getName: (state) => state.user.first_name + " " + state.user.last_name,
		getUser: (state) => state.user,
		getRole: (state) => state.user.role,
		getPermissions: (state) => state.user.permissions,
		getUserDepot: (state) => state.user.depot,
		getUserTimezone: (state) => state.user.depot?.timezone || "Europe/Moscow"
	},

	actions: {
		reset() {
			this.$reset()
		},
		async init(quiet: boolean = true) {
			try {
				const profile = await getUserInfo(quiet)
				this.user.email = profile.email
				this.user.id = profile.id
				this.user.role = profile.role
				this.user.first_name = profile.first_name
				this.user.last_name = profile.last_name
				this.user.middle_name = profile.middle_name
				this.user.position = profile.position
				this.user.permissions = profile.permissions
				this.user.department = profile.department
				this.user.depot = profile.depot
				return true
			} catch (e) {
				captureMessage("auth:store:user: init failed")
				captureException(e)
				return false
			}
		},
		email(payload: string) {
			this.credentials.email = payload ? payload.toLowerCase() : ""
		},
		password(payload: string) {
			this.credentials.password = payload
		},
		newPassword(payload: string) {
			this.credentials.newPassword = payload
		},
		confirmationPassword(payload: string) {
			this.credentials.confirmationPassword = payload
		},
		code(payload: string) {
			this.credentials.code = payload
		}
	}
})
